import { AmbientLight, Color, DirectionalLight, Mesh, MeshLambertMaterial, PerspectiveCamera, Scene, SphereGeometry, WebGLRenderer } from "three";

const scene = new Scene();
const camera = new PerspectiveCamera(50, window.document.body.clientWidth / window.innerHeight, 0.1, 40);
const light = new DirectionalLight(0xffffff, 0.8);
const model = new Mesh(new SphereGeometry(5, 80, 80), new MeshLambertMaterial({ color: 0xffffff, wireframe: true, wireframeLinewidth: 2 }));
const renderer = new WebGLRenderer();

scene.background = new Color(0x212121);
camera.position.z = 10;
light.position.set(5, 5, 20);
light.castShadow = true;
light.shadow.camera.top = 2;
light.shadow.camera.bottom = - 2;
light.shadow.camera.left = - 2;
light.shadow.camera.right = 2;
light.shadow.camera.near = 0.1;
light.shadow.camera.far = 40;
model.castShadow = true;
model.receiveShadow = true;
model.position.set(5, 0, 5);

scene.add(new AmbientLight(0x666666));
scene.add(light);
scene.add(model);

renderer.setSize(document.body.clientWidth, window.innerHeight);
document.getElementById('gl-canvas')?.appendChild(renderer.domElement);

window.addEventListener("resize", () => {
    camera.aspect = document.body.clientWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(document.body.clientWidth, window.innerHeight);
}, false);

window.addEventListener("scroll", () => {
    model.rotation.x += 0.0035;
    model.rotation.y += 0.0035;
    renderer.render(scene, camera);
}, false);

function animate() {
    requestAnimationFrame(animate);
    model.rotation.x += 0.0005;
    model.rotation.y += 0.0005;
    renderer.render(scene, camera);
}
animate();
